import React from "react";
import { makeStyles } from "../../theme/Theme";
import { Breakpoints } from "../../dto/ApplicationDTO";
import cx from "classnames";
import { Box, BoxProps } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.typography.pxToRem(12),
    marginRight: theme.typography.pxToRem(12),

    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      marginLeft: theme.typography.pxToRem(20),
      marginRight: theme.typography.pxToRem(20),
    },

    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      marginLeft: theme.typography.pxToRem(150),
      marginRight: theme.typography.pxToRem(150),
    },

    [theme.breakpoints.up(Breakpoints.Desktop)]: {
      marginLeft: theme.typography.pxToRem(210),
      marginRight: theme.typography.pxToRem(210),
    },
  },
}));

export function AppLayoutContent({ children, className, ...props }: BoxProps) {
  const classes = useStyles();

  return (
    <Box {...props} className={cx(classes.root, className)}>
      {children}
    </Box>
  );
}
