export enum AppRoutes {
  StartRoot = "/*",
  StartAuth = "/auth",
  StartAgreement = "/agreement",

  /**
   * Dashboard
   */
  DashboardRoot = "dashboard/*",
  Dashboard = "/dashboard",

  ManageFundsRoot = "/briefcase/manage",
  ManageFundsRootLink = "/dashboard/briefcase/manage",
  ManageFundsDepositByRequisites = "/briefcase/manage/deposit-requisite",
  ManageFundsDepositByRequisitesLink = "/dashboard/briefcase/manage/deposit-requisite",
  ManageFundsDepositBySBP = "/briefcase/manage/deposit-sbp",
  ManageFundsDepositBySBPLink = "/dashboard/briefcase/manage/deposit-sbp",
  ManageFundsTransferStrategyToReserve = "/briefcase/manage/strategy-transfer",
  ManageFundsTransferStrategyToReserveLink = "/dashboard/briefcase/manage/strategy-transfer",
  ManageFundsWithdrawalReserveToRequisite = "/briefcase/manage/withdrawal-reserve-requisite",
  ManageFundsWithdrawalReserveToRequisiteLink = "/dashboard/briefcase/manage/withdrawal-reserve-requisite",
  ManageFundsQRCode = "/briefcase/manage/qr-code",
  ManageFundsQRCodeLink = "/dashboard/briefcase/manage/qr-code",
  ManageFundsWithdrawalStrategyToReserveWithClose = "/briefcase/manage/withdrawal-strategy-to-reserve-close",
  ManageFundsWithdrawalReserveToStrategy = "/briefcase/manage/withdrawal-reserve-to-strategy-close",
  ManageFundsWithdrawalReserveToStrategyLink = "/dashboard/briefcase/manage/withdrawal-reserve-to-strategy-close",
  ManageFundsWithdrawalStrategyToStrategy = "/briefcase/manage/withdrawal-strategy-to-strategy",
  ManageFundsWithdrawalStrategyToReserveWithCloseLink = "/dashboard/briefcase/manage/withdrawal-strategy-to-reserve-close",
  ManageFundsWithdrawalStrategyToStrategyLink = "/dashboard/briefcase/manage/withdrawal-strategy-to-strategy",
  ManageFundsWithdrawalStrategyToRequisitesWithClose = "/briefcase/manage/withdrawal-strategy-to-requisites-close",
  ManageFundsWithdrawalStrategyToRequisitesWithCloseLink = "/dashboard/briefcase/manage/withdrawal-strategy-to-requisites-close",
  ManageFundsConversionAgreement = "/briefcase/manage/conversion-agreement",
  ManageFundsConversionAgreementLink = "/dashboard/briefcase/manage/conversion-agreement",

  DashboardFeed = "/feed",
  DashboardFeedLink = "/dashboard/feed",
  DashboardBriefcase = "/briefcase",
  DashboardBriefcaseLink = "/dashboard/briefcase",
  DashboardBriefcaseStrategy = "/briefcase/strategy/:id",
  DashboardBriefcaseStrategyLink = "/dashboard/briefcase/strategy/:id",
  DashboardBriefcaseRequiredInfo = "/briefcase/requiredInfo",
  DashboardBriefcaseRequiredInfoLink = "/dashboard/briefcase/requiredInfo",
  DashboardAppeals = "/appeals",
  DashboardAppealsLink = "/dashboard/appeals",
  DashboardStrategies = "/strategies",
  DashboardStrategiesLink = "/dashboard/strategies",
  DashboardInformation = "/information",
  DashboardInformationLink = "/dashboard/information",

  /**
   * Profile
   */
  ProfileRoot = "profile/*",
  Profile = "/profile",
  ProfileLink = "/profile",

  ProfileEdit = "/edit",
  ProfileEditLink = "/profile/edit",
  ProfileSettings = "/settings",
  ProfileSettingsLink = "/profile/settings",
  ProfileTaxStatusEdit = "/edit/tax-status",
  ProfileTaxStatusEditLink = "/profile/edit/tax-status",
  ProfileFixPersonalData = "/fix/personal-data",
  ProfileFixPersonalDataLink = "/profile/fix/personal-data",
  ProfilePassportEdit = "/edit/passport",
  ProfilePassportEditLink = "/profile/edit/passport",
  ProfilePassportFixErrors = "/fix/passport",
  ProfilePassportFixErrorsLink = "/profile/fix/passport",

  /**
   * Recurrent Operations
   */
  RecurrentOperations = "/briefcase/manage/recurrent-operations",
  RecurrentOperationsLink = "/dashboard/briefcase/manage/recurrent-operations",

  RecurrentOperationsPayment = "/briefcase/manage/recurrent-operations/payment",
  RecurrentOperationsPaymentLink = "/dashboard/briefcase/manage/recurrent-operations/payment",

  /**
   * Operations journal
   */
  OperationsJournalRoot = "/briefcase/operations-journal/",
  OperationsJournal = "/dashboard/briefcase/operations-journal",

  /**
   * Survey
   */
  Survey = "/survey",

  // August
  DashboardAugustInfo = "/august-info",
  DashboardAugustInfoLink = "/dashboard/august-info",

  // document
  DocumentRoot = "/document/*",

  DocumentBuyStrategyLink = "/document/buyStrategy",
  DocumentRiskStrategyLink = "/document/riskStrategy",
  DocumentRiskStrategy = "/riskStrategy",
  DocumentBuyStrategy = "/buyStrategy",
}
