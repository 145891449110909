import {
  isPassportOverdueBeExpired as isPassportOverdueBeExpiredFunc,
  isPeriodUpdatePassport as isPeriodUpdatePassportFunc,
} from "../helpers/ProfileHelpers";
import { useProfileQuery } from "../api/profileApi";
import { dateToFormat, SHORT_DMY_DATE_FORMAT } from "../utils";
import { useMemo } from "react";
import { DateTime } from "luxon";

export type StatusPassportEditing = {
  Invalid: boolean;
  Processing: boolean;
  Done: boolean;
};

const getStatusPassport = (status?: Profile.PassportStatus): StatusPassportEditing => {
  const statusPassportEditingValue = status;

  const passportStatusInvalid = statusPassportEditingValue === "invalid";
  const passportStatusProcessing = statusPassportEditingValue === "processing";
  const passportStatusDone = statusPassportEditingValue === "done";
  if (!statusPassportEditingValue)
    return {
      Done: false,
      Invalid: false,
      Processing: false,
    };
  // return {
  //   Done: false,
  //   Invalid: true,
  //   Processing: false,
  // };
  return {
    Done: passportStatusDone,
    Invalid: passportStatusInvalid,
    Processing: passportStatusProcessing,
  };
};
enum TextBtnStatusPassport {
  FIX = "Исправить данные",
  UPDATE = "Обновить данные",
}
export const usePassportStatus = () => {
  const { data: passportData } = useProfileQuery();
  const passport = passportData?.data?.passport;

  const statusPassportEditingValue = passport?.statusPassport;
  // const expirationDate = "2023-02-03";
  const expirationDate = passport?.expirationDate ?? DateTime.now().plus({ year: 4 }).toString();

  const expirationDateShortFormat = dateToFormat({
    format: SHORT_DMY_DATE_FORMAT,
    /* Баг luxon, что 1 первое число месяца в 24:00 отображает
     * название предыдущего месяца. В качестве фикса сдвигаем дату на пару
     * часов вперед */
    date: DateTime.fromISO(expirationDate).plus({ hours: 4 }),
  });

  const isPeriodUpdatePassport = useMemo(() => {
    return isPeriodUpdatePassportFunc(expirationDate, passport?.daysWarningReplacePassport);
  }, [expirationDate, passport?.daysWarningReplacePassport]);

  const isPassportOverdueBeExpired = useMemo(() => {
    return isPassportOverdueBeExpiredFunc(expirationDate);
  }, [expirationDate]);

  const statusPassportEditing: StatusPassportEditing = useMemo(() => {
    return getStatusPassport(statusPassportEditingValue);
  }, [statusPassportEditingValue]);

  // const isHideAlertsWithWithExpirationDate = useMemo(() => {
  //   if (!statusPassportEditing.Done) return true;
  // }, [statusPassportEditing.Done]);

  const requireUpdatePassportByExpirationDate = useMemo(() => {
    return isPassportOverdueBeExpired;
  }, [isPassportOverdueBeExpired]);

  const isShowButtonUpdatePassport = useMemo(() => {
    if (statusPassportEditing.Processing) {
      return false;
    }
    return isPeriodUpdatePassport || isPassportOverdueBeExpired || statusPassportEditing.Invalid;
  }, [
    isPassportOverdueBeExpired,
    isPeriodUpdatePassport,
    statusPassportEditing.Invalid,
    statusPassportEditing.Processing,
  ]);

  const textAlertPassportStatus = {
    profile: {
      invalid:
        "В процессе рассмотрения вашей заявки на изменение паспортных данных были выявлены ошибки. Пожалуйста, актуализируйте данные.",
      processing: `Ваши паспортные данные находятся на проверке`,
      periodUpdatePassport: `Вам необходимо обновить паспортные данные. Иначе с <span style="white-space: nowrap">${expirationDateShortFormat}</span> Вы не сможете выводить средства.`,
      passportOverdueBeExpired: `Ваши паспортные данные необходимо обновить. Вы не можете выводить средства.`,
    },
    fund: {
      invalidPeriodUpdatePassport: `В процессе рассмотрения вашей заявки на изменение паспортных данных были выявлены ошибки в заполненных данных. Пожалуйста, актуализируйте данные, иначе с ${expirationDateShortFormat} Вы не сможете выводить средства.`,
      periodUpdatePassport: `Пожалуйста, обновите паспортные данные в связи с оканчивающимся сроком действия. С <span style="white-space: nowrap">${expirationDateShortFormat}</span> Вы не сможете выводить средства.`,

      processingOverdueBeExpired: "Ваши паспортные данные находятся на проверке",
      invalidOverdueBeExpired:
        "В процессе рассмотрения вашей заявки на изменение паспортных данных были выявлены ошибки в заполненных данных. Пожалуйста, актуализируйте данные, чтобы быстрее получить расширенный доступ к портфелю.",
      passportOverdueBeExpired:
        "Паспортные данные устарели. Возможность выводить средства отключена. \n" +
        "Пожалуйста, обновите данные.",
    },
  };

  const buttonTextAlertPassportStatus = {
    invalid: TextBtnStatusPassport.FIX,
    processing: undefined,
    periodUpdatePassport: TextBtnStatusPassport.UPDATE,
    passportOverdueBeExpired: TextBtnStatusPassport.UPDATE,
  };

  const canWithdraw = useMemo(() => {
    return !isPassportOverdueBeExpired;
  }, [isPassportOverdueBeExpired]);

  return {
    canWithdraw,
    textAlertPassportStatus,
    buttonTextAlertPassportStatus,
    isShowButtonUpdatePassport,
    statusPassportEditing,
    expirationDateShortFormat,
    isPeriodUpdatePassport,
    isPassportOverdueBeExpired,
    requireUpdatePassportByExpirationDate,
  };
};
