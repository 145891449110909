import { DateTime } from "luxon";
import { StatusContent } from "../components/manage-funds/ManageFundsCheckStatus";
import { SignStatus as Status } from "../dto/FundsDTO";

import { store } from "../store/configureStore";
import { settingsApi } from "../api/settingsApi";

export function isTwoFactorAuthenticationEnabled(
  twoFactor?: Profile.TwoFactorAuthenticationProps,
): boolean {
  return !!twoFactor?.enabled;
}

export function isInvalidPassportStatus(passport: Profile.PassportProps): boolean {
  return passport.statusPassport === "invalid";
}

export function isPassportOverdueBeExpired(
  expirationDate: Profile.PassportProps["expirationDate"],
): boolean {
  if (expirationDate) {
    const now = DateTime.now();
    return now > DateTime.fromISO(expirationDate);
  }
  return false;
}

export const isPeriodUpdatePassport = (
  expirationDate: Profile.PassportProps["expirationDate"],
  days = 90,
): boolean => {
  if (expirationDate) {
    const now = DateTime.now();
    const diffUnitDays = DateTime.fromISO(expirationDate).diff(now, "days").days;
    return diffUnitDays > 0 && diffUnitDays <= days;
  }
  return false;
};
export function isPassportProcessing(passport: Profile.PassportProps): boolean {
  return passport.statusPassport === "processing";
}

export function isPassportSoonBeExpired(passport: Profile.PassportProps, days = 0): boolean {
  const isValid = passport.statusPassport !== "invalid";
  if (isValid && passport?.expirationDate) {
    const expirationDate = DateTime.fromISO(passport?.expirationDate);
    const diff = expirationDate.diff(DateTime.now(), "days");

    return diff.days <= days;
  }

  return false;
}

export function isInvalidPassport(passport: Profile.PassportProps): boolean {
  const isInvalidStatus = isInvalidPassportStatus(passport);
  const isSoonBeExpired = isPassportSoonBeExpired(passport, passport.daysWarningReplacePassport);

  return isInvalidStatus || isSoonBeExpired;
}

export function isProfileEditable(
  passport?: Profile.PassportProps,
  twoFactor?: Profile.TwoFactorAuthenticationProps,
) {
  if (!passport || !twoFactor) {
    return false;
  }

  return !isInvalidPassport(passport) && isTwoFactorAuthenticationEnabled(twoFactor);
}

export function getTaxStatusContent(): StatusContent {
  return {
    [Status.InProcess]: {
      title: "Ожидание",
      content:
        "Пожалуйста, не покидайте экран и не закрывайте страницу, документы в процессе отправки.",
    },
    [Status.Success]: {
      title: "Документы отправлены",
      content:
        "Документы отправлены нашим специалистам. Когда данные пройдут проверку, Ваш налоговый статус будет изменён.",
    },
  };
}

export function getPassportStatusContent(): StatusContent {
  return {
    [Status.InProcess]: {
      title: "Ожидание",
      content:
        "Пожалуйста, не покидайте экран и не закрывайте страницу, документы в процессе отправки.",
    },
    [Status.Success]: {
      title: "Документы отправлены",
      content: "Ожидайте обновления данных.",
    },
  };
}

export const changeStatusRegularReplenish = async (flag: boolean) => {
  return await store.dispatch(
    settingsApi.endpoints?.changeSettings.initiate({ isAskAutoCompletion: flag }),
  );
};
