import { isString, toString } from "lodash";
import { BaseQueryFn, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query";

import packageJson from "../../package.json";
import { HOST } from "../constants/ApiConstants";
import { RootState } from "../store/configureStore";
import { AppError, AppErrorProps } from "./AppError";
import { AppEnvironment, ResponseCode } from "../dto/ApplicationDTO";
import { generateSignature, getLocalTimeStamp, mergeQuery } from "../utils";
import { authLogout } from "./AuthHelpers";
import { ApiRoutes } from "../constants/ApiRoutes";

enum ApiHeaders {
  Timestamp = "X-Timestamp",
  Signature = "X-Signature",
  ApiScene = "X-Api-Scene",
  SessionId = "X-Session-Id",
  OsVersion = "X-Os-Version",
  AppVersion = "X-App-Version",
  ApiVersion = "X-Api-Version",
  ApiPlatform = "X-Api-Platform",
}

const BASE_HEADERS = {
  Accept: "application/json",
  [ApiHeaders.ApiPlatform]: "Web",
  [ApiHeaders.AppVersion]: packageJson.version,
};

interface ExtraOptionsProps {
  readonly withSignature?: boolean;
}

interface BaseQueryFnErrorProps extends Pick<AppErrorProps, "text" | "code" | "data"> {
  readonly originalStatus: number;
}

type BaseQueryFnType = BaseQueryFn<
  string | FetchArgs,
  Application.ResponseProps<any>,
  BaseQueryFnErrorProps,
  ExtraOptionsProps,
  Record<string, unknown>
>;

const baseQuery: BaseQueryFnType = fetchBaseQuery() as BaseQueryFnType;

export enum ApiMethod {
  Get = "GET",
  Post = "POST",
  Patch = "PATCH",
  Delete = "DELETE",
}

function getApiScene(environment: Application.AppEnvironmentType): string {
  if (environment === AppEnvironment.Development) {
    return "Testing";
  }

  return "Production";
}

function getUrl(url: string) {
  return `${HOST}/${url}`;
}

interface FormatUrlProps {
  readonly url: string;
  readonly state: RootState;
  readonly extraOptions?: ExtraOptionsProps;
}

type AddSessionAnsTimeStampUrlArgsType = {
  url: string;
  sessionId: string | undefined;
  widthHost?: boolean;
};
export const addSessionAnsTimeStampUrl = ({
  widthHost = true,
  url,
  sessionId,
}: AddSessionAnsTimeStampUrlArgsType) => {
  const timeStamp = getLocalTimeStamp({ zone: "Europe/Moscow" });

  return mergeQuery(widthHost ? getUrl(url) : url, {
    ts: timeStamp,
    sessionId,
  });
};

function formatUrl({ state, url, extraOptions }: FormatUrlProps): string {
  const fullUrl = addSessionAnsTimeStampUrl({ url, sessionId: state.auth.sessionId });

  if (extraOptions?.withSignature !== false) {
    const signature = generateSignature({
      url: fullUrl,
      code: state.auth.code,
    });

    return mergeQuery(fullUrl, { sg: signature });
  }

  return fullUrl;
}

const transformURlForDemoUser = (url: string, isDemoUser: boolean): string => {
  const excludeUrlForDemoUser: string[] = [
    ApiRoutes.Agreement,
    ApiRoutes.GetQrCodeAuth,
    ApiRoutes.CheckQrCodeAuth,
  ];
  const urlParse = new URL(getUrl(url));
  const isExcludeDemoUrl = excludeUrlForDemoUser.includes(urlParse.pathname.slice(1));

  if (!isDemoUser || url.includes("public") || isExcludeDemoUrl) return url;
  return "cold/" + url;
};

function getApiArgs(
  state: RootState,
  args: string | FetchArgs,
  extraOptions?: ExtraOptionsProps,
): string | FetchArgs {
  const isDemo = state.application.modeUser === "cold" || state.application.modeUser === "hot";
  if (isString(args)) {
    return formatUrl({ state, url: transformURlForDemoUser(args, isDemo), extraOptions });
  }

  return {
    ...args,
    url: formatUrl({ state, url: transformURlForDemoUser(args.url, isDemo), extraOptions }),
  };
}

function combineHeaders(base: Headers, adding?: Application.ApiHeadersType): Headers {
  if (!adding) {
    return base;
  }

  const headers = new Headers(base);

  if (adding instanceof Headers) {
    adding.forEach((value: string | undefined, key: string) => {
      if (value && key) {
        headers.set(key, value);
      }
    });
  } else if (Array.isArray(adding)) {
    adding.forEach((header) => {
      const [key, value] = header;

      if (value && key) {
        headers.set(key, value);
      }
    });
  } else {
    const keys = Object.keys(adding);
    const values = Object.values(adding);

    keys.forEach((key, idx) => {
      const value = values[idx];

      if (value && key) {
        headers.set(key, value);
      }
    });
  }

  return headers;
}

function formatArgs(
  state: RootState,
  args: string | FetchArgs,
  extraOptions?: ExtraOptionsProps,
): FetchArgs {
  const baseApiArgs = getApiArgs(state, args, extraOptions);
  const apiScene = getApiScene(state.application.environment);

  const headers = new Headers(BASE_HEADERS);

  const timeStamp = getLocalTimeStamp({ zone: "Europe/Moscow" });

  headers.set(ApiHeaders.Timestamp, timeStamp);
  headers.set(ApiHeaders.SessionId, state.auth.sessionId ?? "");

  if (extraOptions?.withSignature !== false) {
    const fullUrl = addSessionAnsTimeStampUrl({
      url: isString(args) ? args : args.url,
      sessionId: state.auth.sessionId,
    });

    const signature = generateSignature({
      code: state.auth.code,
      url: fullUrl,
    });

    headers.set(ApiHeaders.Signature, signature);
  }

  headers.set("X-Api-Scene", apiScene);

  if (isString(baseApiArgs)) {
    return {
      headers,
      url: baseApiArgs,
    };
  }

  return {
    ...baseApiArgs,
    headers: combineHeaders(headers, baseApiArgs.headers),
  };
}

let isUnauthorized = false;

export function fetchQuery(): BaseQueryFnType {
  return async (args, api, extraOptions) => {
    const state = api.getState() as RootState;

    const formattedArgs = formatArgs(state, args, extraOptions);

    const result = await baseQuery(formattedArgs, api, extraOptions);

    if (result.data?.code === ResponseCode.Unauthorized) {
      if (!isUnauthorized) {
        const formattedArgs = formatArgs(state, ApiRoutes.Logout, {});

        await baseQuery(formattedArgs, api, {});

        authLogout(api.dispatch);

        isUnauthorized = true;
      }
      // @ts-ignore
    } else if (result.error?.status === 500) {
      return {
        error: new AppError({
          // @ts-ignore
          text: result?.error?.data?.text || "",
          // @ts-ignore
          code: result?.error?.data?.code || 1,
          // @ts-ignore
          status: result.error?.status,
        }),
      };
    } else if (result.data?.code !== ResponseCode.Success) {
      return {
        ...result,
        error: new AppError({
          text: result.data?.text || toString(result.error?.data),
          code: result.data?.code,
          data: result.data?.data,
          status: result.error?.originalStatus,
        }),
      };
    }

    if (isUnauthorized && api.endpoint === "checkCode") {
      isUnauthorized = false;
    }

    return { ...result, data: result.data } as any;
  };
}
