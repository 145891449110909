import cx from "classnames";
import React, { ReactNode } from "react";

import { LogoIcon } from "../icons/LogoIcon";
import { makeStyles, useIsTabletDown } from "../../theme/Theme";
import { AppLayoutContent } from "./AppLayoutContent";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import { modeUserInSystemSelector } from "../../slices/applicationSlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "inherit",
    marginLeft: 0,
    marginRight: 0,
    marginTop: "10px",

    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      maxWidth: "100%",
      width: "100%",
      padding: "0 20px",
      marginTop: 20,
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      padding: `0`,
      paddingRight: 150 * 2,
      marginLeft: 150,
      marginTop: 20,
    },
    [theme.breakpoints.up(Breakpoints.Desktop)]: {
      padding: `0`,
      paddingRight: 210 * 2,
      marginLeft: 210,
      marginTop: 18,
    },
  },
  logoWrapper: {
    marginRight: 116,
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      marginRight: 40,
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      marginRight: 116,
    },
  },
}));

interface Props {
  readonly className?: string;
  readonly classNameContent?: string;
  readonly children?: ReactNode;
}

export function AppLayoutHeaderContent({ children, className, classNameContent }: Props) {
  const classes = useStyles();
  const modeUser = useSelector(modeUserInSystemSelector);

  const isHotUser = modeUser === "hot";
  const isSmallDown = useIsTabletDown();

  return (
    <AppLayoutContent className={cx(classes.root, className)}>
      <div className={classes.logoWrapper}>
        {!isHotUser ? (
          <NavLink to={AppRoutes.DashboardBriefcaseLink}>
            <LogoIcon />
          </NavLink>
        ) : (
          !isSmallDown && <LogoIcon />
        )}
      </div>
      <div className={cx(classNameContent)}>{children}</div>
    </AppLayoutContent>
  );
}
