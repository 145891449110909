import { KeyboardEvent } from "react";

function filterLettersKeepSpacesAndPunctuation(text: string): string {
  return text.replace(/[^a-zA-Z\s\u0400-\u04FF]/gu, "");
}

export function filterCyrillicAndSpaces(text: string): string {
  return text.replace(/[^\u0400-\u04FF\s]/gu, "");
}

export function filterDigits(text: string): string {
  return text.replace(/[^0-9]/gu, "");
}

export const validateField = (
  event: KeyboardEvent<HTMLDivElement>,
  type: Survey.InputCharactersType,
) => {
  const target = event.nativeEvent.target;

  if (type === "only-letters") {
    // @ts-ignore
    target.value = filterLettersKeepSpacesAndPunctuation(target.value);
  }

  if (type === "only-numbers") {
    // @ts-ignore
    target.value = filterDigits(target.value);
  }

  if (type === "only-сyrillic-letters") {
    // @ts-ignore
    target.value = filterCyrillicAndSpaces(target.value);
  }
};

export const limitByLength = (input: HTMLInputElement, limit: string) =>
  input?.setAttribute("maxlength", limit);
