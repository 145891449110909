import { Breakpoints } from "../dto/ApplicationDTO";

export function createMuiTypographyComponent(): MuiTheme.MuiButton {
  return {
    styleOverrides: {
      root: ({ theme }) => ({
        fontFamily: "SFPro Display, sans-serif",
        "&.MuiTypography-h0": {
          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: "34px",
            lineHeight: "34px",
          },
          [theme.breakpoints.only(Breakpoints.Mobile)]: {
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "32px",
          },
        },
        "&.MuiTypography-h1": {
          [theme.breakpoints.up(Breakpoints.Mobile)]: {
            fontSize: "24px",
            lineHeight: "110%",
          },
          [theme.breakpoints.up(Breakpoints.Tablet)]: {
            fontSize: "24px",
            lineHeight: "24px",
          },
          [theme.breakpoints.up(Breakpoints.Laptop)]: {
            fontSize: "28px",
            lineHeight: "30px",
          },
        },
        "&.MuiTypography-h3": {
          [theme.breakpoints.down(Breakpoints.Tablet)]: {
            fontSize: "22px",
            lineHeight: "32px",
          },
        },
        "&.MuiTypography-button1": {
          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: "14px",
            lineHeight: "17px",
          },
        },
        "&.MuiTypography-button-filled": {
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "17px",
        },
        "&.MuiTypography-input1": {
          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: "16px",
            lineHeight: "17px",
          },
        },
        "&.MuiTypography-input2": {
          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: "17px",
            lineHeight: "24px",
          },
        },
        "&.MuiTypography-link1": {
          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: "14px",
            lineHeight: "14px",
          },
        },
        "&.MuiTypography-text1": {
          [theme.breakpoints.down(Breakpoints.Laptop)]: {
            fontSize: "12px",
            lineHeight: "14px",
          },
        },
        "&.MuiTypography-text2": {
          [theme.breakpoints.up(Breakpoints.Laptop)]: {
            fontSize: "14px",
            lineHeight: "17px",
          },
          [theme.breakpoints.down(Breakpoints.Laptop)]: {
            fontSize: "14px",
            lineHeight: "14px",
          },
        },
        "&.MuiTypography-text5": {
          [theme.breakpoints.down(Breakpoints.Laptop)]: {
            fontSize: "10px",
          },
        },
        "&.MuiTypography-auth1": {
          [theme.breakpoints.down(Breakpoints.Desktop)]: {
            fontSize: "50px",
            lineHeight: "55px",
          },
          [theme.breakpoints.down(Breakpoints.Tablet)]: {
            fontSize: "30px",
            lineHeight: "35px",
          },
        },
        "&.MuiTypography-auth2": {
          [theme.breakpoints.down(Breakpoints.Desktop)]: {
            fontSize: "25px",
            lineHeight: "28px",
          },
          [theme.breakpoints.down(Breakpoints.Tablet)]: {
            fontSize: "15px",
            lineHeight: "20px",
          },
        },
        "&.MuiTypography-auth3": {
          [theme.breakpoints.down(Breakpoints.Desktop)]: {
            fontSize: "13px",
            lineHeight: "15px",
          },
        },
      }),
    },
  };
}
