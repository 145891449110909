import { store } from "../store/configureStore";
import { requisiteApi } from "../api/requisiteApi";

export const fillRequisiteBankDetails = (
  requisite: Requisite.ShortProps | undefined,
): Promise<Requisite.Requisite | undefined> => {
  if (!requisite) return Promise.resolve(undefined);
  return new Promise(async (resolve, reject) => {
    if (!requisite) return resolve(undefined);
    const bikRequisite = requisite?.bik;
    try {
      const { data: dataBankList } = await store?.dispatch(
        requisiteApi.endpoints.getInfoBanks.initiate({ bankBik: bikRequisite }),
      );
      if (!dataBankList?.data?.length) {
        return resolve(requisite);
      }
      resolve({
        ...requisite,
        nameBank: dataBankList?.data[0].name,
        iconBank: dataBankList?.data[0]?.base64,
      });
    } catch (e) {
      resolve(requisite);
    }
    return requisite;
  });
};

export const DefaultRequisite: Requisite.Requisite = {
  account: "",
  bik: "",
  iconBank: "",
  id: 0,
  nameBank: "",
  status: "ready",
  type: {
    id: 0,
    code: "",
    icon: "",
    rate: 0,
    title: "",
  },
};
export const getReadyRequisiteWithBank = (
  requisite: Requisite.Requisite,
): Requisite.ShortPropsWithInfoByBank => {
  if (!requisite || !("nameBank" in requisite)) {
    return DefaultRequisite as Requisite.ShortPropsWithInfoByBank;
  }
  return { ...requisite };
};

export const transformDataRequisiteWithBankDetails = async (
  baseQueryReturnValue: Application.ResponseProps<Requisite.ShortProps[]>,
): Promise<Application.ResponseProps<Requisite.ResponseRequisiteList>> => {
  const readyRequisiteDefault = baseQueryReturnValue.data?.find((item) => item.status === "ready");
  const listWithBankDetails = baseQueryReturnValue.data?.length
    ? await Promise.all(
        baseQueryReturnValue?.data.map(async (req) => await fillRequisiteBankDetails(req)),
      )
    : [];
  const readyRequisite = await fillRequisiteBankDetails(readyRequisiteDefault);

  return {
    ...baseQueryReturnValue,
    data: {
      list: listWithBankDetails,
      readyRequisite,
      readyRequisiteWithBank: getReadyRequisiteWithBank(readyRequisite),
    },
  };
};
