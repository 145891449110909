import { CSSProperties, useMemo } from "react";
import { usePassportStatus } from "../../hooks/usePassportStatus";
import { Alert } from "../ui/Alert";

interface Props {
  readonly sx?: CSSProperties;
}

export function ManageFundsInvalidPassportError({ sx }: Props) {
  const {
    statusPassportEditing,
    isPassportOverdueBeExpired,
    textAlertPassportStatus,
    isPeriodUpdatePassport,
  } = usePassportStatus();
  const titleText = useMemo(() => {
    if (isPassportOverdueBeExpired) {
      if (statusPassportEditing.Done) {
        return textAlertPassportStatus.fund.passportOverdueBeExpired;
      }
      if (statusPassportEditing.Processing) {
        return textAlertPassportStatus.fund.processingOverdueBeExpired;
      }
      if (statusPassportEditing.Invalid) {
        return textAlertPassportStatus.fund.invalidOverdueBeExpired;
      }
    }
    if (isPeriodUpdatePassport) {
      if (statusPassportEditing.Done) {
        return textAlertPassportStatus.fund.periodUpdatePassport;
      }
      if (statusPassportEditing.Invalid) {
        return textAlertPassportStatus.fund.invalidPeriodUpdatePassport;
      }
      if (statusPassportEditing.Processing) {
        return "";
      }
    }
    return "";
  }, [
    isPassportOverdueBeExpired,
    isPeriodUpdatePassport,
    statusPassportEditing.Done,
    statusPassportEditing.Invalid,
    statusPassportEditing.Processing,
    textAlertPassportStatus.fund.invalidOverdueBeExpired,
    textAlertPassportStatus.fund.invalidPeriodUpdatePassport,
    textAlertPassportStatus.fund.passportOverdueBeExpired,
    textAlertPassportStatus.fund.periodUpdatePassport,
    textAlertPassportStatus.fund.processingOverdueBeExpired,
  ]);

  if (!titleText) return null;
  return <Alert sx={sx} innerText={titleText} />;
}
